






import Vue from "vue"
import BuzzInAction from "@/components/GroupTeams/Common/Games/BuzzIn/BuzzInAction"
import BigUglyButton from "./BigUglyButton.vue"

export default Vue.extend({
  name: "BuzzBtn",
  components: {
    BigUglyButton,
    BuzzInAction
  }
})
