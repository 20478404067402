var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "social-team-users",
      attrs: { column: "", "justify-center": "", "fill-height": "" },
    },
    [
      _vm.isMissionCardHidden && _vm.isViewerHostLike
        ? _c(
            "v-flex",
            {
              staticClass: "visibility-link",
              attrs: { "d-flex": "", "align-center": "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.onMissionCardToggle.apply(null, arguments)
                },
              },
            },
            [
              _c("v-icon", { staticClass: "visibility-icon" }, [
                _vm._v(" visibility "),
              ]),
              _c("span", { staticClass: "text" }, [
                _vm._v(" " + _vm._s(_vm.missionType) + " "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "social-team-users__content",
          class: {
            "social-team-users__content--compress": _vm.isViewerHostLike,
            "social-team-users__content--multi-team": _vm.isMultiTeamLayout,
          },
        },
        [
          !_vm.isMissionCardHidden && !_vm.isUnlimitedTeams
            ? _c(
                "v-layout",
                {
                  staticClass: "buzz-mission",
                  class: {
                    "buzz-mission--clickable": _vm.isViewerHostLike,
                    "buzz-mission--enlarged": _vm.enlargedUI,
                  },
                  attrs: { column: "", "justify-center": "" },
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "flip-transition", mode: "out-in" } },
                    [
                      _vm.cardStatus === "asset"
                        ? _c("AssetMapper", {
                            staticClass: "buzz-asset-mapper",
                            attrs: {
                              priceIsRightAnswer: _vm.priceIsRightAnswer,
                              showToggleAsset: !!(
                                _vm.mission && _vm.mission.photo
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "transition",
                    { attrs: { name: "flip-transition", mode: "out-in" } },
                    [
                      _vm.cardStatus === "game" &&
                      _vm.missionType !== _vm.MissionType.Slides
                        ? _c(
                            "v-layout",
                            {
                              attrs: {
                                column: "",
                                "justify-center": "",
                                "mission-container": "",
                              },
                            },
                            [
                              _vm.isQuestionCards
                                ? _c("QuestionCards")
                                : _c(
                                    "MissionContainer",
                                    {
                                      attrs: {
                                        mode: _vm.mode,
                                        mission: _vm.mission,
                                        points: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "transition",
                                        {
                                          attrs: {
                                            name: "play-mode-wrap__transition",
                                            mode: "out-in",
                                          },
                                        },
                                        [
                                          _vm.isBuzzIn
                                            ? _c("TextOnly", {
                                                attrs: {
                                                  mode: _vm.mode,
                                                  mission: _vm.mission,
                                                },
                                              })
                                            : _vm.isPriceIsRightComplete
                                            ? _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "social-team-users__price-is-right-answer-txt",
                                                  },
                                                  [_vm._v(" ANSWER: ")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "social-team-users__price-is-right-answer-value",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.mission.answer
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _vm.missionType ===
                                              _vm.MissionType.TwoTruths
                                            ? _c("TwoTruthsAsset", {
                                                attrs: {
                                                  mode: _vm.mode,
                                                  mission: _vm.mission,
                                                },
                                              })
                                            : _vm.isLipdub
                                            ? _c("Lipdub", {
                                                key:
                                                  "lipdub-" +
                                                  _vm.currentMissionID,
                                                attrs: {
                                                  mode: _vm.mode,
                                                  mission: _vm.mission,
                                                },
                                              })
                                            : [
                                                _vm.MissionType.Text,
                                                _vm.MissionType.FreeForm,
                                                _vm.MissionType.TeamName,
                                              ].includes(_vm.missionType)
                                            ? _c("TextInput", {
                                                attrs: {
                                                  mode: _vm.mode,
                                                  mission: _vm.mission,
                                                },
                                              })
                                            : _vm.missionType ===
                                              _vm.MissionType.Represent
                                            ? _c("Represent", {
                                                attrs: {
                                                  mode: _vm.mode,
                                                  mission: _vm.mission,
                                                },
                                              })
                                            : _vm.missionType ===
                                              _vm.MissionType.RoyalRumble
                                            ? _c("RoyalRumble", {
                                                attrs: {
                                                  mode: _vm.mode,
                                                  mission: _vm.mission,
                                                },
                                              })
                                            : _vm.isHandMovementMissionType
                                            ? _c("HandMovement", {
                                                attrs: {
                                                  mode: _vm.mode,
                                                  mission: _vm.mission,
                                                },
                                              })
                                            : _vm.isFamilyFeud
                                            ? _c("SurveySays", {
                                                attrs: {
                                                  mode: _vm.mode,
                                                  mission: _vm.mission,
                                                  buzz: _vm.buzz,
                                                },
                                              })
                                            : _vm.isCharades
                                            ? _c("Charades")
                                            : _c("TextOnly", {
                                                attrs: {
                                                  mission: _vm.mission,
                                                  mode: _vm.mode,
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.isViewerHostLike && _vm.isPriceIsRightMissionType
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "social-team-users__shuffle-btn",
                          attrs: { color: "secondary", absolute: "", fab: "" },
                          on: { click: _vm.shuffleUsers },
                        },
                        [_c("v-icon", [_vm._v("shuffle")])],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.buzzBtnVisible ? _c("BuzzBtn") : _vm._e(),
          _c(
            "div",
            { class: { "z-20": _vm.isCharades } },
            _vm._l(_vm.usersInRows, function (row, index) {
              return _c(
                "div",
                { key: index, staticClass: "social-player-transition-wrap" },
                _vm._l(row, function (player) {
                  return _c("SocialPlayerCard", {
                    key: player.id + "-" + player.teamID,
                    staticClass: "buzz-player-col",
                    class: {
                      "buzz-player-col--scale": _vm.blowUpPriceIsRightWinner,
                    },
                    attrs: {
                      priceIsOver: _vm.isPriceIsRightComplete,
                      priceCount: _vm.priceCount,
                      allowKeys: _vm.nOfSelectedPlayers === 1,
                      showScore: !_vm.isPriceIsRightMissionType,
                      readOnly:
                        _vm.isQuestionCards || !_vm.showRating(player.teamID),
                      teamIcon: !_vm.isQuestionCards,
                      buzzCount: _vm.getBuzzInIndex(player.id),
                      user: player,
                    },
                    on: {
                      priceSubmit: function (price) {
                        return _vm.priceSubmit(price, player)
                      },
                      onStarred: function (num) {
                        return _vm.onStarred(num, player)
                      },
                      changeTask: function ($event) {
                        return _vm.setNextTeamIndex(player.teamID)
                      },
                      dblclick: function ($event) {
                        return _vm.deselectUser(player)
                      },
                    },
                  })
                }),
                1
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }