<template>
  <v-layout column justify-center fill-height class="social-team-users">
    <v-flex
      d-flex
      align-center
      v-if="isMissionCardHidden && isViewerHostLike"
      class="visibility-link"
      @click.stop="onMissionCardToggle"
    >
      <v-icon class="visibility-icon"> visibility </v-icon>
      <span class="text">
        {{ missionType }}
      </span>
    </v-flex>

    <div
      class="social-team-users__content"
      :class="{
        'social-team-users__content--compress': isViewerHostLike,
        'social-team-users__content--multi-team': isMultiTeamLayout
      }"
    >
      <v-layout
        v-if="!isMissionCardHidden && !isUnlimitedTeams"
        column
        justify-center
        class="buzz-mission"
        :class="{
          'buzz-mission--clickable': isViewerHostLike,
          'buzz-mission--enlarged': enlargedUI
        }"
      >
        <!-- Asset -->
        <transition name="flip-transition" mode="out-in">
          <AssetMapper
            v-if="cardStatus === 'asset'"
            class="buzz-asset-mapper"
            :priceIsRightAnswer="priceIsRightAnswer"
            :showToggleAsset="!!(mission && mission.photo)"
          />
        </transition>
        <!--  -->
        <!-- Mission -->
        <transition name="flip-transition" mode="out-in">
          <v-layout
            v-if="cardStatus === 'game' && missionType !== MissionType.Slides"
            column
            justify-center
            mission-container
          >
            <QuestionCards v-if="isQuestionCards" />

            <MissionContainer v-else :mode="mode" :mission="mission" points>
              <transition name="play-mode-wrap__transition" mode="out-in">
                <TextOnly :mode="mode" :mission="mission" v-if="isBuzzIn" />
                <div v-else-if="isPriceIsRightComplete">
                  <div class="social-team-users__price-is-right-answer-txt">
                    ANSWER:
                  </div>
                  <div class="social-team-users__price-is-right-answer-value">
                    {{ mission.answer }}
                  </div>
                </div>
                <TwoTruthsAsset
                  :mode="mode"
                  :mission="mission"
                  v-else-if="missionType === MissionType.TwoTruths"
                />
                <Lipdub
                  :mode="mode"
                  :mission="mission"
                  :key="`lipdub-${currentMissionID}`"
                  v-else-if="isLipdub"
                />
                <TextInput
                  :mode="mode"
                  :mission="mission"
                  v-else-if="
                    [
                      MissionType.Text,
                      MissionType.FreeForm,
                      MissionType.TeamName
                    ].includes(missionType)
                  "
                />
                <Represent
                  v-else-if="missionType === MissionType.Represent"
                  :mode="mode"
                  :mission="mission"
                />
                <RoyalRumble
                  v-else-if="missionType === MissionType.RoyalRumble"
                  :mode="mode"
                  :mission="mission"
                />
                <HandMovement
                  v-else-if="isHandMovementMissionType"
                  :mode="mode"
                  :mission="mission"
                />
                <SurveySays
                  v-else-if="isFamilyFeud"
                  :mode="mode"
                  :mission="mission"
                  :buzz="buzz"
                />
                <Charades v-else-if="isCharades" />

                <TextOnly v-else :mission="mission" :mode="mode" />
              </transition>
            </MissionContainer>
          </v-layout>
        </transition>
        <v-btn
          v-if="isViewerHostLike && isPriceIsRightMissionType"
          @click="shuffleUsers"
          color="secondary"
          absolute
          fab
          class="social-team-users__shuffle-btn"
        >
          <v-icon>shuffle</v-icon>
        </v-btn>
      </v-layout>

      <BuzzBtn v-if="buzzBtnVisible" />

      <div :class="{ 'z-20': isCharades }">
        <div
          v-for="(row, index) in usersInRows"
          :key="index"
          class="social-player-transition-wrap"
        >
          <SocialPlayerCard
            v-for="player in row"
            :key="`${player.id}-${player.teamID}`"
            :class="{ 'buzz-player-col--scale': blowUpPriceIsRightWinner }"
            :priceIsOver="isPriceIsRightComplete"
            :priceCount="priceCount"
            :allowKeys="nOfSelectedPlayers === 1"
            :showScore="!isPriceIsRightMissionType"
            :readOnly="isQuestionCards || !showRating(player.teamID)"
            :teamIcon="!isQuestionCards"
            :buzzCount="getBuzzInIndex(player.id)"
            :user="player"
            class="buzz-player-col"
            @priceSubmit="price => priceSubmit(price, player)"
            @onStarred="num => onStarred(num, player)"
            @changeTask="setNextTeamIndex(player.teamID)"
            @dblclick="deselectUser(player)"
          />
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import * as moment from "moment"
import _ from "lodash"
import shuffle from "knuth-shuffle-seeded"

import { db, ServerValue } from "@/firebase"
import PriceIsRightService from "@/services/price-is-right.service"

import User from "@shared/User"

import MediaType from "@shared/enums/Media"
import MissionType from "@shared/enums/Mission"

import { RtbPill } from "@/components/ui"
import BuzzBtn from "@/components/GroupTeams/Common/Games/BuzzIn/BuzzBtn"
import useEnlargedUI from "@/use/useEnlargedUI"
import SocialPlayerCard from "../../Player/SocialPlayerCard/SocialPlayerCard.vue"

const AssetMapper = () =>
  import("@/components/GroupTeams/Common/AssetMapper.vue")
const TextOnly = () =>
  import("@/components/GroupTeams/Common/Games/TextOnly.vue")
const TextInput = () =>
  import("@/components/GroupTeams/Common/Games/TextInput.vue")
const Lipdub = () => import("@/components/GroupTeams/Common/Games/Lipdub.vue")
const TwoTruthsAsset = () =>
  import("@/components/GroupTeams/Common/Games/TwoTruthsAsset.vue")
const MissionContainer = () =>
  import("@/components/GroupTeams/Common/Games/MissionContainer.vue")
const Represent = () =>
  import("@/components/GroupTeams/Common/Games/Represent.vue")
const RoyalRumble = () =>
  import("@/components/GroupTeams/Common/Games/RoyalRumble.vue")
const HandMovement = () =>
  import(
    "@/components/GroupTeams/Common/Games/HandMovement/HandMovementPlayArea.vue"
  )

const NUMBER_OF_STARS = 5

import useCharades from "@/components/GroupTeams/Common/Games/Charades/useCharades"
import useBuzzIn from "@/use/useBuzzIn"

export default {
  name: "SocialTeamUsers",
  components: {
    Charades: () =>
      import("@/components/GroupTeams/Common/Games/Charades/Charades.vue"),
    QuestionCards: () =>
      import(
        "@/components/GroupTeams/Common/Games/QuestionCards/QuestionCards.vue"
      ),
    SurveySays: () =>
      import("@/components/GroupTeams/Common/Games/SurveySays.vue"),
    SocialPlayerCard,
    TextOnly,
    TextInput,
    MissionContainer,
    AssetMapper,
    Lipdub,
    Represent,
    TwoTruthsAsset,
    RtbPill,
    RoyalRumble,
    HandMovement,
    BuzzBtn
  },
  beforeDestroy() {
    this.votingRef?.off("value", this.onVoteUpdate)
  },
  props: {
    mode: String
  },
  setup() {
    const {
      getIndex: getBuzzInIndex,
      isBuzzed,
      buzz,
      seed,
      incrementSeed,
      swapBuzzUsers,
      max: maxBuzzIn
    } = useBuzzIn()

    const { enlargedUI } = useEnlargedUI()
    enlargedUI

    const {
      getTeamAnswer,
      isFinished,
      isCharades,
      getIsCompleted,
      setNextTeamIndex
    } = useCharades()

    return {
      seed,
      shuffleUsers: incrementSeed,
      setNextTeamIndex,
      getBuzzInIndex,
      isBuzzed,
      buzz,
      getTeamAnswer,
      isFinished,
      isCharades,
      getIsCompleted,
      swapBuzzUsers,
      enlargedUI,
      maxBuzzIn
    }
  },
  data() {
    return {
      votes: {},
      MissionType
    }
  },
  computed: {
    ...mapGetters("auth", ["clientID", "isHost", "isAudit"]),
    ...mapGetters("group", ["isMultiTeam"]),
    ...mapGetters([
      "onlineUsersArray",
      "missions",
      "game",
      "gameID",
      "orgID",
      "isScribe"
    ]),
    usersInRows() {
      const users = this.selectedWithScore
      if (!this.isMultiTeamLayout) return [users]
      const index = Math.ceil(this.nOfSelectedPlayers / 2)
      return [users.slice(0, index), users.slice(index)]
    },
    isViewerHostLike() {
      return this.$store.getters["group/isViewerHostLike"]
    },
    blowUpPriceIsRightWinner() {
      return this.isViewerPresenter && this.isPriceIsRightComplete
    },
    votesArray() {
      return Object.entries(this.votes || {}).map(([id, value]) => ({
        id,
        ...value
      }))
    },
    priceIsRightAnswer() {
      if (!this.isPriceIsRightComplete) return null

      if (this.isEveryoneFailedPriceIsRight) return "Everyone Guessed Over"

      if (!this.mission?.noCurrency)
        return this.numberWithCommas("$" + this.mission?.answer)

      return this.numberWithCommas(this.mission?.answer)
    },
    votingRef() {
      return this.orgID && this.gameID && this.currentMissionID
        ? db
            .auxiliary()
            .ref(`org/${this.orgID}/game/${this.gameID}/votes`)
            .orderByChild("missionID")
            .equalTo(this.currentMissionID)
        : null
    },
    isMissionCardHidden() {
      return Boolean(this.missions?.[this.currentMissionID]?.hidden)
    },
    isUnlimitedTeams() {
      return Boolean(this.missions?.[this.currentMissionID]?.unlimitedTeams)
    },
    isViewerPresenter() {
      return User.isPresenter(this.$store.state.auth.user)
    },
    media() {
      return this.gameStatus?.media
    },
    cardStatus() {
      const current = this.media
      return current &&
        Object.values(MediaType).some(media => media === current)
        ? "asset"
        : "game"
    },
    isMultiTeamLayout() {
      if (!this.isMultiTeam) return false

      const nOfSelectedPlayers = this.nOfSelectedPlayers

      if (this.isMissionCardHidden && nOfSelectedPlayers > 5) return true

      if (!this.isMissionCardHidden && nOfSelectedPlayers > 3) return true

      return false
    },
    nOfSelectedPlayers() {
      return this.selectedWithScore.length
    },
    priceCount() {
      return this.missionPlaysArray.length + 1
    },
    regularSelectedWithScore() {
      const teams = this.teams || {}
      const plays = this.missionPlaysArray

      const isWinner = user => {
        if (this.isCharades) {
          return this.getIsCompleted(user)
        }

        return plays.some(
          item =>
            item.userID === user.id &&
            (parseInt(item.score) > 0 || item.result) &&
            item.show
        )
      }

      return this.selectedUsers
        .map(user => ({
          ...user,
          answer: this.getCharadesAnswer(user),
          icon: teams[user.teamID]?.icon,
          score: this.getScore(user.id, user.teamID),
          winner: isWinner(user)
        }))
        .sort(User.sort)
    },
    priceIsRightSelectedWithScore() {
      const teams = this.teams || {}
      const plays = this.missionPlaysArray

      const predicates = []

      if (this.isViewerPresenter && this.isPriceIsRightComplete)
        predicates.push(user => user.priceWinner)

      const getUserScore = user =>
        plays.reduce((acc, val) => {
          if (val.userID === user.id) return acc
          return acc + (parseInt(val.score) || 0)
        }, 0)

      const users = this.onlineUsersArray
        .filter(({ selected }) => selected)
        .map(user => {
          const score = getUserScore(user)

          return {
            ...user,
            icon: teams[user.teamID]?.icon,
            score,
            winner: score > 0,
            priceWinner: this.isUserPriceIsRightWinner(user),
            priceIsRight: this.numberWithCommas(
              this.getUserPriceIsRightText(user.id)
            )
          }
        })

      return shuffle(users, this.seed)
        .map((user, index) => ({ ...user, index: index + 1 }))
        .filter(user => predicates.every(predicate => predicate(user)))
    },
    isPriceIsRightMissionType() {
      return this.mission?.behavior === MissionType.PriceIsRight
    },
    isFamilyFeudMissionType() {
      return this.mission?.behavior === MissionType.FamilyFeud
    },
    selectedWithScore() {
      if (this.isPriceIsRightMissionType)
        return this.priceIsRightSelectedWithScore

      if (this.isBuzzIn) return this.buzzedUsers

      return this.regularSelectedWithScore
    },
    buzzedUsers() {
      const usersByTeamID = this.usersByTeamID
      return this.buzz.reduce((acc, item) => {
        const users = usersByTeamID[item.teamID] ?? []
        const user = users.find(user => user.id === item.userID)
        if (user == null) return acc
        acc.push({ ...user, score: this.getScore(user.id, user.teamID) })
        return acc
      }, [])
    },
    selectedUsers() {
      const royalRumbleID = this.gameStatus?.royalRumbleID
      const users = this.onlineUsersArray

      return royalRumbleID && royalRumbleID !== 1000
        ? users.filter(user => user.selected && user.teamID === royalRumbleID)
        : users.filter(({ selected }) => selected)
    },
    ratingByUserID() {
      const votesByUserID = this.votesByUserID
      return this.selectedUsers.reduce((acc, val) => {
        const id = val?.id
        const array = votesByUserID[id] || []
        if (array.length === 0) return acc
        const n = array.reduce((acc, val) => acc + (parseInt(val.vote) || 0), 0)
        acc[id] = parseInt(n / array.length)
        return acc
      }, {})
    },
    gameStatus() {
      return this.$store.getters.gameStatus
    },
    missionStarted() {
      return this.gameStatus?.missionStarted
    },
    missionPlays() {
      return this.$store.getters.missionPlays
    },
    missionPlaysArray() {
      return this.$store.getters.missionPlaysArray
    },
    missionType() {
      return this.mission?.behavior
    },
    teams() {
      return this.$store.getters.chats
    },
    user() {
      return this.$store.getters.user
    },
    currentTeamID() {
      return this.$store.getters.teamID
    },
    gameID() {
      return this.$store.getters.gameID
    },
    orgID() {
      return this.$store.getters.orgID
    },
    currentMissionID() {
      return this.mission?.id
    },
    mission() {
      return this.$store.getters.getCurrentMission
    },
    playerCanRate() {
      return (
        this.missionType === MissionType.PlayerRating && !this.user.selected
      )
    },
    points() {
      return parseInt(this.mission?.points) || 0
    },
    isLipdub() {
      return this.missionType === MissionType.Lipdub
    },
    isBuzzIn() {
      return this.missionType === MissionType.BuzzIn
    },
    isQuestionCards() {
      return this.missionType === MissionType.QuestionCards
    },
    isFamilyFeud() {
      return this.missionType === MissionType.FamilyFeud
    },
    usersByTeamID() {
      return this.onlineUsersArray.reduce((acc, val) => {
        if (acc[val.teamID]) {
          acc[val.teamID].push(val)
        } else {
          acc[val.teamID] = [val]
        }
        return acc
      }, {})
    },
    isEveryoneFailedPriceIsRight() {
      if (!this.isPriceIsRightMissionType) return false
      if (!this.gameStatus?.paused) return false
      const isZeroPlay = play => play?.score === 0
      const users = this.onlineUsersArray.filter(User.isScribe)
      const plays = this.missionPlaysArray.filter(isZeroPlay)
      return plays.length >= users.length
    },
    isPriceIsRightComplete() {
      if (!this.isPriceIsRightMissionType) return false
      if (!this.gameStatus?.paused) return false
      const users = this.onlineUsersArray.filter(User.isScribe)
      const plays = this.missionPlaysArray
      return (
        plays.length > 0 && users.length > 0 && users.length <= plays.length
      )
    },
    votesByUserID() {
      return this.votesArray.reduce((acc, val) => {
        if (acc[val.ratedUserID]) {
          acc[val.ratedUserID].push(val)
        } else {
          acc[val.ratedUserID] = [val]
        }
        return acc
      }, {})
    },
    isHandMovementMissionType() {
      return this.missionType === MissionType.HandMovement
    },
    buzzBtnVisible() {
      return (
        !this.isViewerHostLike &&
        !this.isViewerPresenter &&
        this.isBuzzIn &&
        this.buzz.length < this.maxBuzzIn &&
        !this.isBuzzed &&
        this.cardStatus === "asset"
      )
    }
  },
  watch: {
    votingRef: {
      handler(newValue, oldValue) {
        oldValue?.off("value", this.onVoteUpdate)
        newValue?.on("value", this.onVoteUpdate)
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(["updateSocialMissionStatus"]),
    numberWithCommas(string) {
      return String(string).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    isUserPriceIsRightWinner(user) {
      return (
        this.isPriceIsRightComplete &&
        this.getUserPlay(user?.id)?.score === Number(this.mission.points)
      )
    },
    getUserPrice(userID) {
      return this.getUserPlay(userID)?.correct || 0
    },
    getUserPlay(userID) {
      return this.missionPlaysArray.find(play => play.userID === userID)
    },
    getPriceDifference(userPrice) {
      return parseFloat(
        (
          (parseFloat(this.mission.answer) * 1000 -
            parseFloat(userPrice) * 1000) /
          1000
        ).toFixed(2)
      )
    },
    getUserPriceIsRightText(userID) {
      const price = this.getUserPrice(userID)

      if (!this.isPriceIsRightComplete) return price || ""

      let difference = this.getPriceDifference(price)

      if (difference < 0) return "OVER"

      if (difference === 0) return "Exact Guess!"

      if (!this.mission.noCurrency) difference = "$" + difference

      return difference + " Under"
    },
    priceSubmit(price, user) {
      PriceIsRightService.submit(user, price)
    },
    onVoteUpdate(snapshot) {
      this.votes = snapshot.val() || {}
    },
    async onMissionCardToggle() {
      if (!this.isViewerHostLike) return
      await this.updateSocialMissionStatus({
        missionID: this.currentMissionID,
        hidden: !this.isMissionCardHidden
      })
    },
    showRating(teamID) {
      return (
        (this.playerCanRate && teamID != this.currentTeamID) ||
        this.isViewerHostLike
      )
    },
    async onStarred(rate, user) {
      const currentMissionID = this.currentMissionID
      const userID = user.id

      const sTime = new Date()

      const prevVoteId = this.votesArray.find(
        vote => vote.ratedUserID == userID && vote.votingUserID == this.user.id
      )?.id

      await this.$store.dispatch("addVote", {
        ...(prevVoteId && { id: prevVoteId }),
        teamID: 0,
        ratedUserID: user.id,
        votingUserID: this.user.id,
        missionID: currentMissionID,
        mission: this.mission.name,
        points: this.mission.points,
        time: moment(sTime).unix(),
        show: false,
        vote: rate
      })

      const lastUserPlay = this.missionPlays
        .slice()
        .reverse()
        .find(({ userID }) => userID === user.id)

      const foundPlayID = _.get(lastUserPlay, "id", null)

      // working because of pure luck
      this.$nextTick(() => {
        const { behavior, name, points } = this.mission

        const user = this.selectedWithScore.find(({ id }) => id === userID)
        if (!user) return 0
        let score = parseInt(user.score) || 0

        const teamID = user.teamID

        if (behavior !== MissionType.FamilyFeud) {
          this.$store.dispatch("addPlay", {
            ...(foundPlayID && { id: foundPlayID }),
            correct: "vote",
            missionID: this.currentMissionID,
            result: true,
            show: true,
            teamID,
            time: moment(sTime).unix(),
            userID: user.id,
            mission: name,
            score,
            behavior,
            points
          })
        }

        const currentTeamScore = this.teams[teamID]?.totalScore || 0

        let teamScore

        if (prevVoteId) {
          const lastPlayScore = _.get(lastUserPlay, "score", 0)
          teamScore = currentTeamScore - lastPlayScore + score
        } else {
          teamScore = currentTeamScore + score
        }

        const totalscorePath = `org/${this.orgID}/game/${this.gameID}/teams/${teamID}/totalScore`
        const update = { [totalscorePath]: teamScore || 0 }

        const { onlineUsersArray: users, clientID } = this
        const ratedTeamID = user.teamID

        for (const user of users) {
          if (user.teamID !== ratedTeamID) continue
          const n = parseInt(score) || 0
          const val = ServerValue.increment(n)
          update[`client/${clientID}/userscore/${user.id}`] = val
        }

        db.auxiliary().ref().update(update)
      })
    },
    getCharadesAnswer(user) {
      if (
        this.isCharades &&
        (this.isViewerHostLike || this.isFinished || this.getIsCompleted(user))
      ) {
        return this.getTeamAnswer(user)
      }
    },
    getScore(userID, teamID) {
      let m = this.points
      if (this.isLipdub) {
        const n = (this.usersByTeamID[teamID] || []).length
        if (n < 1) return 0
        m = this.points / n
      }
      const avg = this.ratingByUserID[userID] || 0
      return parseInt((m * avg) / NUMBER_OF_STARS)
    },
    deselectUser(player) {
      if (!this.isHost) return

      if (this.isBuzzIn) {
        this.swapBuzzUsers({ ...player, id: "" })
      }
      db.auxiliary().ref(`org/1/users/${player.id}/selected`).set(false)
    }
  }
}
</script>

<style lang="scss">
.social-team-users {
  --gap: 16px;

  &__shuffle-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -21px;
    width: 42px;
    height: 42px;

    .v-btn__content {
      height: auto;
    }
  }

  &__price-is-right-answer-txt {
    font-size: 15px;
  }

  &__price-is-right-answer-value {
    font-size: 64px;
    font-family: Right Grotesk, san-serif;
  }

  &__transition-enter-active,
  &__transition-leave-active {
    transition: opacity ease 0.5s;
    opacity: 1;
  }
  &__transition-enter {
    opacity: 0;
  }
  &__transition-leave-to {
    opacity: 0;
  }

  .flip-transition-enter-active,
  .flip-transition-leave-active {
    transition: all ease 0.3s;
    transform: rotateX(0deg);
  }
  .flip-transition-enter-active {
    transition-delay: 0.3s;
  }
  .flip-transition-enter {
    transform: rotateY(90deg);
  }
  .flip-transition-leave-to {
    transform: rotateY(-90deg);
  }
  .social-player-transition-wrap {
    display: flex;
    align-items: center;
    flex-shrink: 1;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  .buzz-result {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 0.75rem;
  }
  .buzz-result,
  .buzz-asset-mapper {
    @extend .rtb-border, .rtb-border-radius, .rtb-box-shadow;
    background: radial-gradient(
      137.87% 137.87% at 49.24% 9.52%,
      #2c2c2c 0%,
      #000000 100%
    );
    overflow: hidden;
  }
  .buzz-mission {
    user-select: none;
    position: relative;
    margin-left: var(--gap);
    margin-right: var(--gap);
    width: 33.33%;
    height: 100%;
    max-width: 40%;
    min-width: 30vw;
    min-height: 45vmin;
    perspective: 2000px;
    transition: margin 0.5s ease 0.5s;
    &--clickable {
      cursor: pointer;
    }
    &--enlarged {
      min-height: 90vh;
      width: 100%;
    }
  }
  .buzz-player-col {
    align-items: center;
    width: 34vmin;
    max-width: 300px;
    min-width: 180px;
    &--scale {
      transform: scale(1.5) translateX(15%);
    }
  }
  .visibility-link {
    background-color: $color-white;
    height: 30px;
    padding: 0 7px;
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 10;
    border: 2px solid $primary_accent_color;
    border-radius: 1em;
    user-select: none;
    cursor: pointer;
    i {
      color: $color-secondary-dark;
    }
    .text {
      padding-left: 3px;
      color: $primary_accent_color;
      text-decoration: underline;
    }
    &:hover {
      .text {
        filter: brightness(1.5);
      }
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;

    &--compress {
      .buzz-player-col {
        width: 13vw;
      }
      .buzz-mission {
        min-height: 38vmin;
      }
    }

    &--multi-team {
      .buzz-player-col {
        width: 22vmin;
        max-width: 22vmin;
      }
      .social-player {
        font-size: 0.9em;
        .buzz-player-name {
          font-size: 0.8em;
        }
      }
    }
  }
}
</style>
