var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BuzzInAction", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var onBuzzIn = ref.onBuzzIn
          return [
            _c("BigUglyButton", {
              nativeOn: {
                click: function ($event) {
                  return onBuzzIn.apply(null, arguments)
                },
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }